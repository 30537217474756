import { faJira } from '@fortawesome/free-brands-svg-icons';
import { faBookReader, faDatabase, faDownload, faFilm, faFolder, faHouse, faLayerGroup, faLink, faServer, faTv } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from "react";

const LinkBar = () => {
  let renderedLinks = []

  var links = [
    {
      "icon": faHouse,
      "text": "Home",
      "url": "https://home.clintgeek.com",
    },
    {
      "icon": faLink,
      "text": "Links",
      "url": "https://links.clintgeek.com",
    },
    {
      "icon": faDownload,
      "text": "SAB",
      "url": "https://sab.clintgeek.com",
    },
    {
      "icon": faTv,
      "text": "Sonarr",
      "url": "https://sonarr.clintgeek.com",
    },
    {
      "icon": faFilm,
      "text": "Radarr",
      "url": "https://radarr.clintgeek.com",
    },
    {
      "icon": faBookReader,
      "text": "Books",
      "url": "https://books.clintgeek.com",
    },
    {
      "icon": faFolder,
      "text": "S3",
      "url": "https://s3.console.aws.amazon.com/s3/buckets/moneymatch-com?region=us-east-1&prefix=production-staging/"
    },
    {
      "icon": faServer,
      "text": "EB",
      "url": "https://console.aws.amazon.com/elasticbeanstalk/home?region=us-east-1#/environments"
    },
    {
      "icon": faLayerGroup,
      "text": "EC2",
      "url": "https://us-east-1.console.aws.amazon.com/ec2/v2/home?region=us-east-1#Instances:v=3;tag:elasticbeanstalk:environment-name=moneymatch-com-development"
    },
    {
      "icon": faDatabase,
      "text": "MongoDB",
      "url": "https://account.mongodb.com/account/login"
    },
    {
      "icon": faJira,
      "text": "Jira",
      "url": "https://ignitesales.atlassian.net/jira/software/c/projects/IGNITE/boards/27"
    },
  ]

  links.forEach(link => {
    var text = link.text || '';
    renderedLinks.push(
        <div key={link.text} className="link">
          <a className="link__button" href={link.url} role="button">
            <FontAwesomeIcon className='link__icon' icon={link.icon} />
            <span className='link__text'>{text}</span>
          </a>
        </div>
      )
  })

  return (
    <div className="linkBar">
      {renderedLinks}
    </div>
  )
}

export default LinkBar;