import Ticker, { NewsTicker } from 'nice-react-ticker';
import React, { useEffect, useState } from 'react';

const FeedReader = () => {
  const [feedData, setFeedData] = useState([]);
  const feeds = [
    'https://rsshub.app/apnews/topics/apf-topnews',
    'https://rsshub.app/apnews/topics/business',
    'https://rsshub.app/reuters/world/us',
    'https://rsshub.app/bbc/world',
    'https://rsshub.app/cnbc/rss',
    'https://rsshub.app/ollama/blog',
    'https://rsshub.app/epicgames/freegames',
    'https://rsshub.app/fortnite/news',
  ];

  const fetchFeedData = async () => {
    let combinedData = [];

    try {
      const requests = feeds.map(feed =>
        fetch('https://proxy.clintgeek.com?url=' + feed)
          .then(response => response.text())
          .then(rawData => JSON.parse(rawData))
      );

      const results = await Promise.all(requests);

      results.forEach(data => {
        if (Array.isArray(data)) {
          combinedData = combinedData.concat(data);
        } else {
          console.error('No entries found or entries is not an array');
        }
      });

      setFeedData(combinedData);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchFeedData();
  }, []);

  const shuffleArray = (array) => {
    let shuffled = array.slice();

    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));

      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }

    return shuffled;
  }

  const timeFormatter = (dateStr) => {
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: 'America/Chicago',
    };
    const formatter = new Intl.DateTimeFormat('en-US', options);
    const date = dateStr ? new Date(dateStr) : new Date();

    return formatter.format(date);
  }

  const renderFeedData = (dataForRender) => {
    return shuffleArray(dataForRender).map((item, index) => {
      const formattedTime = timeFormatter(item.published);

      return (<div key={index} className='feed-item'>
        <NewsTicker id={index + 1} key={index + 1} title={item.title} url={item.link} meta={formattedTime} />
      </div>)
    });
  };

  return (
    <div className='newsticker' >
      <Ticker isNewsTicker={true} slideSpeed={1000}>
        {renderFeedData(feedData)}
      </Ticker>
    </div>
  );
};

export default FeedReader;
