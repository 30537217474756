import Clock from 'react-live-clock';

const TimeDate = () => {
  return (
    <div className="timeDate">
      <div className="time">
        <Clock
          format={'h:mm a'}
          ticking={true}
          timezone={'America/Chicago'}
        />
      </div>
      <div className="date">
        <Clock
          format={'dddd | MMMM Do, YYYY'}
          ticking={true}
          timezone='America/Chicago'
        />
      </div>
    </div>
  )
}

export default TimeDate;