import React, { useEffect, useState } from "react";

const WeatherBar = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const handleInterval = () => {
      setCount(prevCount => prevCount + 1);
    };

    // Set an interval to update the state every 10 minutes
    const intervalId = setInterval(handleInterval, 600000);

    return () => clearInterval(intervalId);
  }, []);


  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://app2.weatherwidget.org/js/?id=ww_9c6c10bf3e3c5';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="weatherBar">
      <div id="ww_9c6c10bf3e3c5" v='1.3' loc='auto' a='{"t":"responsive","lang":"en","sl_lpl":1,"ids":[],"font":"Arial","sl_ics":"one","sl_sot":"fahrenheit","cl_bkg":"#FFFFFF00","cl_font":"rgba(255,255,255,1)","cl_cloud":"#d4d4d4","cl_persp":"#2196F3","cl_sun":"#FFC107","cl_moon":"#FFC107","cl_thund":"#FF5722","sl_tof":"7","cl_odd":"#00000000"}'>
        More forecasts: <a href="https://oneweather.org/fuerteventura/august/" id="ww_9c6c10bf3e3c5_u" target="_blank">Weather in Fuerteventura in August</a>
      </div>
    </div>
  )
}

export default WeatherBar