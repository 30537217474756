import { useEffect } from 'react';
import '../css/App.css';
import FeedReader from './components/FeedReader';
import LinkBar from './components/LinkBar';
import TimeDate from './components/TimeDate';
import WeatherBar from './components/WeatherBar';

function App() {
  const getBackgroundImage = () => {
    const bgUrl = "https://picsum.photos/1920/1080.webp";
    document.body.style.backgroundImage = `url(${bgUrl})`;
    document.body.style.backgroundRepeat = 'no-repeat';
    document.body.style.backgroundSize = 'cover';
  };

  useEffect(() => {
    getBackgroundImage();
  }, []);

  return (
    <div className="App">
      <div className='header'>
        <FeedReader />
        <LinkBar />
      </div>
      <div className='content'>
        <TimeDate />
      </div>
      <div className='footer'>
        <WeatherBar />
      </div>
    </div>
  );
}

export default App;
